import { Col, Container, Row, Button, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

const WelcomeIntro = () => {

    const handleClick = () => {
        window.open("https://latpay.com/contact-us/", "_blank");
    };

    return (
        <div className="Latpay-qa mt-5">
            <Container>
                <Row className="p-5 justify-content-center">
                    <Col lg={12} md={6} xs={12} className="text-center">
                        {/* <h3 className="mt-2">Latpay - Developer Support</h3>
                        <div style={{ marginBottom: '20px' }}>Our developer support team is here to assist you with integrating our services seamlessly.
                            Whether you're looking for APIs, documentation, or troubleshooting, we're just a click away!</div> */}
                        {/* <div>
                            <Button 
                                variant="primary" 
                                className="rounded-pill mt-3" 
                                style={{ backgroundColor: '#122051', borderColor: '#122051' }}
                                onClick={handleClick}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                                Let's Connect
                            </Button>
                        </div> */}
                        <h3 className="mt-2">Get Started</h3>
                        <div>Our experience and dedicated team of payment innovators create solutions that suit the specific needs of your business.</div>
                        <Container>
                            <Row className="p-4">
                                <Col lg={4} md={6} xs={12}>
                                    <Card style={{ border: '1px solid #122051', padding: '1px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center">
                                        <div><h6 className="mt-2" style={{ color: '#122051' }}>mPOS Device & Integration</h6></div>
                                        <div><h6 className="mt-2" style={{ color: '#122051' }}>Recurring Payments</h6></div>
                                        <div><h6 className="mt-2" style={{ color: '#122051' }}>Hosted Payment Pages</h6></div>
                                            <div><h6 className="mt-2" style={{ color: '#122051' }}>Virtual Terminals</h6></div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={4} md={6} xs={12}>
                                    <Card style={{ border: '1px solid #122051', padding: '1px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center">
                                        <div><h6 className="mt-2" style={{ color: '#122051' }}>Real-Time Payments</h6></div>
                                        <div><h6 className="mt-2" style={{ color: '#122051' }}>Alternative Payments</h6></div>
                                        <div><h6 className="mt-2" style={{ color: '#122051' }}>Ecommerce Shopping Carts</h6></div>
                                        <div><h6 className="mt-2" style={{ color: '#122051' }}>Dedicated Support</h6></div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={4} md={6} xs={12}>
                                    <Card style={{ border: '1px solid #122051', padding: '1px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center">
                                            <div><h6 className="mt-2" style={{ color: '#122051' }}>Direct Debit Facilities</h6></div>
                                            <div><h6 className="mt-2" style={{ color: '#122051' }}>Fraud Management Engine</h6></div>
                                            <div><h6 className="mt-2" style={{ color: '#122051' }}>Mobile Payment Solutions</h6></div>
                                            <div><h6 className="mt-2" style={{ color: '#122051' }}>Security with PCI Compliance</h6></div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WelcomeIntro;