import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import FormList from "../forms/formlist";
import LatpayFormSignIn from "../forms/lpsformsignin";
import { useAuthState } from "react-firebase-hooks/auth";
import LatpayFooter from "../layout/footer";
import LatpayCopyright from "../layout/copyright";
import LatpayHeader from "../layout/header";
import { auth } from "../firebase-config";
import { useEffect, useState } from "react";

const AuthCheck = () => {
    let params = useParams();
    let formId = params.id || "none";
    const [user, loading, error] = useAuthState(auth);

    const form = [...FormList.filter((f) => f.id===formId),{}][0];
    const steps = form.steps||[];
    const initialData = form.data||{};

    useEffect(() => {
        if (loading) return;
        if (!user) {
            console.log(`Not logged in`);
            //return navigate("/");
        }
        console.log(`Logged in as ${user?.uid}`);
      }, [user, loading]);

    return ( <>
        <LatpayHeader />
        <Container className="py-5 text-left">
            <Row>
                <Col></Col>  
                <LatpayFormSignIn asCol={true} steps={steps} init={initialData} formId={formId}/>
                <Col></Col>
            </Row>
        </Container>
        <LatpayFooter/>
        <LatpayCopyright/>
        </>);

}
 
export default AuthCheck;