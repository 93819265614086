import { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormList from "../forms/formlist";
import DB from "../services/firestoredb";
import { auth } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";

const LatpayHeader = () => {

  const [nav,setNav] = useState([]);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    const getNav = async() => {
        const navData = await DB.getNav();
        const nx = navData.main.filter((n) => n.oldSite);
        setNav(nx);//navData.main);
    }
    getNav();

  }, []);

  const handleLogout = () => {
    DB.signOut();
  }

    return (
      <>
        <Navbar variant="light" className="topHeader py-0">
        <Container>
          <Nav className="ms-auto"> 
            <NavDropdown title="Forms" id="forms-nav-dropdown" className="custom-dropdown" style={{fontSize: "13px", fontWeight: 500, color: "#535f8d"}}>
            <NavDropdown.Item to="/afa/postseason" as={Link} target="_blank" style={{fontSize: "13px", fontWeight: 500, color: "#535f8d"}}>AFA - Post Season Form</NavDropdown.Item>
            <NavDropdown.Item to="/afa/inseason" as={Link} target="_blank" style={{fontSize: "13px", fontWeight: 500, color: "#535f8d"}}>AFA - In Season Form</NavDropdown.Item>
            <NavDropdown.Item to="/afa/eoi" as={Link} target="_blank" style={{fontSize: "13px", fontWeight: 500, color: "#535f8d"}}>AFA - Registration</NavDropdown.Item>
            {/* <NavDropdown.Item to="/card/cardnetworklookup" as={Link} target="_blank" style={{fontSize: "13px", fontWeight: 500, color: "#535f8d"}}>Rapyd - Card Lookup</NavDropdown.Item> */}
            </NavDropdown>
            {/* <NavDropdown.Item to={`/merchant/admin`} as={Link}>Application</NavDropdown.Item>
              {FormList.map((f,x) => <NavDropdown.Item key={`${f.id}${x}`} to={`/form/${f.id}`} as={Link}>{f.name}</NavDropdown.Item>)}
            </NavDropdown> */}
            {/* <NavDropdown title="Developers" id="docs-nav-dropdown">
              <NavDropdown.Item rel="noopener noreferrer" target="_blank" href="https://docs.latpay.net">User Guides</NavDropdown.Item>
              <NavDropdown.Item rel="noopener noreferrer" target="_blank" href="https://docs.latpay.net/technical">Technical Documents</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item rel="noopener noreferrer" target="_blank" href="#">Legal Documents</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item to="/demo/bridge" as={Link}>Terminal Bridge Demo</NavDropdown.Item>
              <NavDropdown.Item to="/demo/hps" as={Link}>Hosted Payment Page Demo</NavDropdown.Item>
              <NavDropdown.Item to="/demo/qr" as={Link}>POS QR</NavDropdown.Item>
              <NavDropdown.Item to="/pos/panezski" as={Link}>POS Demo</NavDropdown.Item>
              <NavDropdown.Item to="/demo/keygen" as={Link}>Key Generator Demo</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item to="/demo" as={Link}>All-in-One Demo</NavDropdown.Item>
              <NavDropdown.Item to="/afa/postseason" as={Link}>AFA - Post Season Form</NavDropdown.Item>
            </NavDropdown> */}
            {user ? 
              <Nav.Link to="/" onClick={handleLogout} as={Link} style={{fontSize: "13px", fontWeight: 500, color: "#535f8d"}}>Logout</Nav.Link>
            :
              <Nav.Link to="/" as={Link} style={{fontSize: "13px", fontWeight: 500, color: "#535f8d"}}>Login</Nav.Link>
            }
          </Nav>
        </Container>
        </Navbar>
      <Navbar variant="light" expand="xl">
        <Container>
            <Navbar.Brand><Link to="/"><img src="/images/lat-pay-logo-300x135.png" className="App-logo" alt="logo" /></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {nav.length>0 ? 
              <>
                {nav.map((f,x) => <>{
                  /*f.sub ? 
                    <NavDropdown key={`${f.id}${x}`} title={f.name} id={`forms-nav-dropdown`}>
                      {f.sub.map((s,i) => <NavDropdown.Item key={`${f.id}${x}-${i}`} to={`/main/${f.id}`} as={Link}>{s}</NavDropdown.Item>)}
                    </NavDropdown>
                  :
                    <Nav.Link key={`${f.id}${x}`} to={`/main/${f.id}`} as={Link}>{f.name}</Nav.Link>
                    */
                    <Nav.Link key={`${f.id}${x}`} href={`${f.oldSite}`} style={{fontSize: "13px", fontWeight: 500, color: "#535f8d"}}>{f.name}</Nav.Link>
                }</>)}
              </>
              : <Nav.Link to="/" as={Link} style={{fontSize: "13px", fontWeight: 500, color: "#535f8d"}}>Home</Nav.Link>
            }
          </Nav>
        </Navbar.Collapse>
        </Container>
    </Navbar> 
    </> 
  );
}
 
export default LatpayHeader;