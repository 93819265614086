import { Col, Container, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const HaveQuestions = () => {

    const handleClick = () => {
        window.open("https://latpay.com/contact-us/", "_blank");
    };

    return (
        <div className="Latpay-qa mt-5">
            <Container>
                <Row className="p-5 justify-content-center">
                    <Col lg={3} md={6} xs={12} className="text-center">
                        <h3 className="mt-2">Got a Question?</h3>
                        <div>We are here to assist you.</div>
                        <div>
                            <Button 
                                variant="primary" 
                                className="rounded-pill mt-3" 
                                style={{ backgroundColor: '#122051', borderColor: '#122051' }}
                                onClick={handleClick}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                                Let's Connect
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HaveQuestions;