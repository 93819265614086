import { connectFirestoreEmulator } from "firebase/firestore";
import { useState } from "react";
import { Col, Container, FloatingLabel, Form, Ratio, Row } from "react-bootstrap";
import QRCode from "react-qr-code";
import latpayUrl from "../config";
import LatpayCopyright from "../layout/copyright";
import LatpayFooter from "../layout/footer";
import LatpayHeader from "../layout/header";

const QRPOS = () => {
    const merchants = [
        { "code":"panezski","name":"Panezski Distillers"},
        { "code":"moollar","name":"Moollar"},
        { "code":"sabona","name":"Sabona"},
        { "code":"tahbella","name":"Cafe Tahbella"},
    ];

    const [data,setData] = useState({
        "merchant":"panezski",
        "table":"1",
    });

    const handleChange = (e) => {
        const dx = {...data};
        dx[e.target.name] = e.target.value;
        setData(dx);
    }

    const merchantName = (code) => {
        const l = merchants.filter((m) => m.code==code);
        return l.length===0 ? "" : l[0].name;
    }

    const url = data.table==="" ? 
            `https://${latpayUrl}/pos/${data.merchant}`
        :
            `https://${latpayUrl}/pos/${data.merchant}?t=${data.table}`;

            
    return <>
        <LatpayHeader/>
        <Container className="py-5 text-left">
            <Row>
                <Col md={6} className="text-center mb-3">
                    <div style={{display:"inline-block", borderColor:"#253370", borderStyle:"solid", borderWidth:"10px", width:"280px", height:"280px"}} className="rounded-circle p-5 text-center">
                        <div className="text-center" style={{color:"#253370"}}>{merchantName(data.merchant)}</div>
                    <QRCode 
                            size={128}
                            value={url}
                            fgColor="#B7248F"
                        />
                        <div className="text-center" style={{color:"#253370"}}>{data.table===""?"Takeaway Order":`Table ${data.table}`}</div>
                    </div>
                </Col>
                <Col md={6} className="mb-3" >
                <FloatingLabel label="Demo Business">
                <Form.Select className="mb-3" name="merchant" value={data.merchant||""} onChange={handleChange}>
                        {merchants.map((m) => <option key={m.code} value={m.code}>{m.name}</option>)}
                </Form.Select>
                </FloatingLabel>
                <FloatingLabel label="Table Number (Leave blank for takeaway orders)">
                    <Form.Control name="table" onChange={handleChange} value={data.table||""}/>
                </FloatingLabel>
                </Col>
            </Row>

        </Container>
        <LatpayFooter/>
        <LatpayCopyright/>
    </>;
}

export default QRPOS;