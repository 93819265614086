import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import LPF from "./lpf";
import utils from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { auth } from "../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import CardNetworkLookup from "../card/cardnetworklookup";

const LatpayFormSignIn = ({asCol,steps,init, formId}) => {
    let params = useParams();
    let navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [started, setStarted] = useState(false);

    let step = parseInt(params.step||0);
    console.log(`Step: ${step}`);

    useEffect(() => {
        if (loading) return;
        /* if (!user) {
            console.log(`Not logged in`);
            //return navigate("/");
        }
        console.log(`Logged in as ${user?.uid}`);
        */
        const dx = {...data};
        if (user) {
            console.log("logged in");
            console.log(user);
            dx.signedIn = true;
        } else {
            dx.signedIn = false;
            console.log("NOT logged in");
        }
        setData(dx);
      }, [user, loading]);

    const [data, setData] = useState({...{
        contacts: [{}],
        stakeHolders: [{}],
        //payReq: {},
        country: LPF.countries[0],
        entityType:LPF.entityTypes[0].name
    },...(init||{ })});
    const [userInfo,setUserInfo] = useState({});
    //const [step, setStep] = useState(0);

    const stepList = (st) => {
        try {
            return (steps||{})[st];
        } catch (e) {
            return "";
        }

    };

    const stepForm = (stepName,okCheck) => {
        switch (stepName) {
            case LPF.sn.businessInfo:
                return LPF.businessInfo(data,handleChange,okCheck);
            case LPF.sn.primaryContact:
                return LPF.primaryContact(data,handleChange,okCheck);
            case LPF.sn.testTypes:
                return LPF.testTypes(data,handleChange,okCheck);
            case LPF.sn.paymentTypes:
                return LPF.paymentTypes(data,handleChange,okCheck);
            case LPF.sn.stakeHolders:
                return LPF.stakeHolders(data,handleChange,okCheck);
            case LPF.sn.allDoneTestAcc:
                return LPF.allDoneTestAcc(data,okCheck);
            case LPF.sn.allDoneApplication:
                return LPF.allDoneApplication(data,okCheck);
            default:
                return okCheck ? false : <div>Oops, you should never see this message, so clearly there's an issue!</div>
        }
    }

    const stepOK = () => {
        return stepForm(stepList(step),true);
    }

    const handleNext = () => {
        if (stepForm(stepList(step),true)) {
            const st = step+1;
            //setStep(st);
            /*if (stepForm(stepList(st),true)==="done") {
                const dx = {...data};
                dx.done = true;
                setData(dx);
            }*/
            console.log(`nav to ${st}`);
            navigate(`/form/${formId}/${st}`);
            utils.scrollToTop(50);
        }
    }

    const handleBack = () => {
        if (step>0) {
            //setStep(step-1);
            navigate(-1);
        }
    }

    const handleChange = (e) => {
        if (e.target) {
            console.log(e.target);
            const dx = LPF.handleChange(e,data);
            console.log(dx);
            setData(dx);
        } else {
            console.log(e);
            setData(e);
        }
    }

    const resume = () => {
        setStarted(true);
        navigate(`/form/${formId}/${stepList.length}`);
    }

    /*const handleUpdate = (dx) => {
        //console.log(dx);
        setData(dx);
    }*/

    const handleUserInfoChange = (ui) => {
        setUserInfo(ui);
        if (ui.signedIn !== data.signedIn) {
            const dx = {...data};
            dx.signedIn = ui.signedIn;
            if (dx.signedIn) {
                try {
                    dx.contacts[0].email = dx.contacts[0].email || ui.email;
                } catch (e) {

                }
            }
            setData(dx);
        }
    }

    for (let s=0;s<step;s++) {
        // check previous steps, make sure they are not being skipped!
        if (!stepForm(stepList(s),true)) {
            setTimeout(()=>{
                navigate(`/form/${formId}/${s}`);
            },100);
            break;
        }
    }

    const content = () => {
        if (!data.signedIn) return LPF.userLoginNew(userInfo,handleUserInfoChange);

        // if (started) {
        //     return <> 
        //         {stepForm(stepList(step))}
        //         {!(stepForm(stepList(step),true)==="done") && LPF.nextBtns(step,stepOK,handleNext,handleBack)}
        //     </>;
        // }

        // return <>
        //     <h1>Welcome Back!</h1>
        //     <p>Are you ready to continue where we left off previously?</p>
        //     <p><Button variant="indigo" onClick={resume}>Let's go!</Button></p>
        // </>;
        // return(<div class="col-xl-6 col-lg-8 col-md-10 col-sm-12"><CardNetworkLookup/></div>);
        return(<div class="col-xl-6"><CardNetworkLookup/></div>);
        // return (<><CardNetworkLookup/></>);
    
    }

    if (asCol) {

        return data.signedIn ?
            <Col sm={12} md={10} lg={8} xl={6}>{content()}</Col> 
        : 
            <Col sm={10} md={8} lg={6} xl={4}>{content()}</Col>;
    }

    return content();
    

}
 
export default LatpayFormSignIn;