import { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const AfaEoi = () => {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    DateofBirth: "",
    Mobile: "",
    Email: "",
    FootballClub: "",
    PFirstName: "",
    PLastName: "",
    PMobile: "",
    PEmail: "",
    Comment: "",
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setError('');
  };

  const handleSubmit = () => {
    if (!formData.Mobile) {
      setError('This field is mandatory');
    } else {
      console.log(formData);
      // Continue with form submission or further logic
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh", overflow: 'hidden' }}>
      <Container fluid style={{ padding: 0 }}>
        <Row>
          <Col
            style={{
              backgroundColor: "#00a1be",
              height: "143px",
              display: "flex",
              alignItems: "flex-end",
              padding: "10px",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute", // Positioning the overlay absolutely
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.5,
                backgroundImage: 'url(https://latpay.com/wp-content/themes/enfold/images/background-images/dots-mini-light.png)',
                backgroundRepeat: 'repeat',
                zIndex: 1, // Placing the overlay behind the content
              }}
            ></div>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                zIndex: 2,
              }}
            /> */}
            <img
              src="https://latpay.com/wp-content/uploads/2024/05/afa-tlogo-180x180.png"
              alt="Logo"
              style={{
                height: "80px", // Matching this to the font size of the text
                marginRight: "10px", // Spacing between the logo and text
                zIndex: 2
              }}
            />
            <h1
              style={{
                color: "whitesmoke",
                fontSize: "50px",
                textAlign: "center",
                position: "relative", // Positioning the text above the overlay
                zIndex: 2, // Bringing the text in front of the overlay
              }}
            >
              AFA - Registration
            </h1>
          </Col>
        </Row>
        {/* <Row>
          <Col
            style={{
              backgroundColor: "#00a1be",
              height: "143px",
              display: "flex",
              alignItems: "flex-end",
              padding: "10px",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                color: "whitesmoke",
                fontSize: "45px",
                textAlign: "center",
              }}
            >
              AFA 2024 Post-Season Training
            </h1>
          </Col>
        </Row> */}

        <Container style={{ marginTop: "75px", marginBottom: "75px" }}>
          <Row>
            <Col>
              <div style={{ textAlign: "left"}}>
                <p style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  fontFamily: "HelveticaNeue, sans-serif",
                  color: "#253370"
                }}>Player Details</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >First Name</Form.Label>{" "}
                
                <Form.Control
                  type="text"
                  value={formData.FirstName}
                  name="FirstName"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >Last Name</Form.Label>{" "}
                
                <Form.Control
                  type="text"
                  value={formData.LastName}
                  name="LastName"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px", width: "49%" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >Date of Birth</Form.Label>{" "}
                <Form.Control
                  type="date"
                  value={formData.DateofBirth}
                  name="DateofBirth"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >Contact Number</Form.Label>{" "}
                <span style={{ color: "red ", fontSize: "12px", fontFamily: "HelveticaNeue, sans-serif", }}>(Required)</span>
                <Form.Control
                  type="text"
                  value={formData.Mobile}
                  name="Mobile"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
                {error && <div style={{ color: "red", fontSize: "12px" }}>{error}</div>} {/* Error message */}
              </div>
            </Col>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >Contact Email</Form.Label>{" "}
                <Form.Control
                  type="text"
                  value={formData.Email}
                  name="Email"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px", width:"49%" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >Current Club</Form.Label>{" "}
                <Form.Control
                  type="text"
                  value={formData.FootballClub}
                  name="FootballClub"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: "left"}}>
                <p style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  fontFamily: "HelveticaNeue, sans-serif",
                  color: "#253370"
                }}>Parent/Guardian Consent</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >First Name</Form.Label>{" "}
                
                <Form.Control
                  type="text"
                  value={formData.PFirstName}
                  name="PFirstName"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >Last Name</Form.Label>{" "}
                
                <Form.Control
                  type="text"
                  value={formData.PLastName}
                  name="PLastName"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >Contact Number</Form.Label>{" "}
                <Form.Control
                  type="text"
                  value={formData.PMobile}
                  name="PMobile"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >Contact Email</Form.Label>{" "}
                <Form.Control
                  type="text"
                  value={formData.PEmail}
                  name="PEmail"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: "left", marginBottom: "25px" }}>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#666666"
                  }}
                >Comments or Questions</Form.Label>{" "}
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={formData.Comment}
                  name="Comment"
                  onChange={handleChange}
                  style={{
                    // fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "HelveticaNeue, sans-serif",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#00a1be",
              borderColor: "#00a1be",
              color: "white",
              padding: "10px 25px",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: "bold",
              textTransform: "uppercase",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s ease, box-shadow 0.3s ease",
            }}
            // onClick={() => {
            //   console.log(formData);
            // }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Container>

        <Row className="mt-auto">
          <Col
            style={{
              backgroundColor: "#122051",

              padding: "20px",
              justifyContent: "center",
            }}
          >
            <p
              style={{ color: "whitesmoke", fontSize: "11px", marginBottom: "5px" }}
            >
              All Content Copyright © 2024 Latpay | <a href="https://latpay.com/privacy-policy" target="_blank" style={{ textDecoration: "none", color: "#ffffff" }}>Privacy Policy</a> | <a href="https://latpay.com/terms-condition/" target="_blank" style={{ textDecoration: "none", color: "#ffffff" }}>Terms &amp; Conditions</a> | <a href="https://latpay.com/wp-content/uploads/2023/11/Financial-Services-Guide-and-Product-Disclosure-Statement-final-V1.1-14.03.23.pdf" target="_blank" style={{ textDecoration: "none", color: "#ffffff" }}>AU Financial Services Guide</a>
            </p>
            <p
              style={{ color: "whitesmoke", fontSize: "11px", marginBottom: "auto" }}
            >
              Lateral Payment Solutions Pty Ltd (Latpay) ABN 12 610 150 064 is the holder of an Australian Financial Services Licence (AFSL) No. 521901.
            </p>
          </Col>
        </Row>

        <Row>
          <Col
            className="d-flex justify-content-center"
            style={{ backgroundColor: "#fff", padding: "10px" }}
          >
            <img
              src="https://latpay.com/wp-content/uploads/2024/03/logos2.png"
              alt=""
              style={{ height: "50px" }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AfaEoi;