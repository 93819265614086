import LPF from "./lpf";

const FormList = [
    {
        id:"application",
        name:"Merchant Application",
        steps: [
            LPF.sn.primaryContact,
            LPF.sn.businessInfo,
            LPF.sn.paymentTypes,
            LPF.sn.stakeHolders,
            LPF.sn.allDoneApplication,
        ]
    },
    {
        id:"wptest",
        name:"Wordpress Test Account",
        steps: [
            LPF.sn.businessInfo,
            LPF.sn.allDoneTestAcc,
        ],
        data: {
            services: ["Shopping Cart Plugin"],
        },
    },
    {
        id:"ddtest",
        name:"Direct Debit Test Account",
        steps: [
            LPF.sn.businessInfo,
            LPF.sn.testTypes,
            LPF.sn.allDoneTestAcc,
        ],
        data: {
            services: ["Direct Debit"],
        },
    },
    {
        id:"testacc",
        name:"Test Account",
        steps: [
            LPF.sn.businessInfo,
            LPF.sn.testTypes,
            LPF.sn.allDoneTestAcc,
        ],
    },
];

export default FormList;