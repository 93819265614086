import { Col, Container, Row, Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

const SpecaBox = () => {

    const handleClick = () => {
        window.open("https://speca.io/latpay/latpay-api", "_blank");
    };
    const handleClick1 = () => {
        window.open("https://latpay.com/our-solutions/", "_blank");
    };

    return (
        <div className="Latpay-footer mt-5">
            <Container>
                {/* <Row className="p-5">
                    <h4 className="mt-2">APIs and Beyond</h4>
                    <div>Comprehensive solutions crafted by developers for developers, including APIs, mobile applications, content management systems (CMS), and more.</div>
                </Row> */}
                <Row className="p-5">
                    <Row className="p-4">
                        <h3 className="mt-2">APIs and Beyond</h3>
                        <div>Comprehensive solutions crafted by developers for developers, including APIs, mobile applications, content management systems (CMS), and more.</div>
                        <div>
                            <Button
                                variant="primary"
                                className="rounded-pill mt-3"
                                style={{ backgroundColor: '#122051', borderColor: '#122051' }}
                            // onClick={handleClick}
                            >
                                <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                                Let's Connect
                            </Button>
                        </div>
                    </Row>
                    {/* <h4 className="mt-2">APIs and Beyond</h4>
                    <div>Comprehensive solutions crafted by developers for developers, including APIs, mobile applications, content management systems (CMS), and more.</div>
                    <div>
                        <Button
                            variant="primary"
                            className="rounded-pill mt-3"
                            style={{ backgroundColor: '#122051', borderColor: '#122051' }}
                        // onClick={handleClick}
                        >
                            <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                            Let's Connect
                        </Button>
                    </div> */}
                    {/* First Column with Inner Column */}
                    <Col lg={6} md={6} xs={12} className="d-flex">
                        {/* <Card style={{ border: '2px solid blue', padding: '20px', flex: 1 }}> */}
                        <Card style={{ padding: '20px', flex: 1 }}>
                            <Card.Body>
                                <h3 className="mt-2" style={{ color: '#122051' }}>Our Services</h3>
                                <div style={{ color: '#122051' }}>Here is our list of Major Services</div>
                                {/* <Row className="p-4">
                                    <Col lg={4} md={6} xs={12}>
                                        <Card style={{ border: '1px solid #122051', padding: '1px', flex: 1 }}>
                                            <Card.Body>
                                                <FontAwesomeIcon icon={faCode} className="me-2" style={{color: '#122051'}} />
                                                <h4 className="mt-2" style={{color: '#122051'}}>APIs</h4>
                                                <div>Explore our robust APIs designed for seamless integration, enabling developers to create powerful applications efficiently.</div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} md={6} xs={12}>
                                        <Card style={{ border: '1px solid #122051', padding: '1px', flex: 1 }}>
                                        <Card.Body>
                                                <FontAwesomeIcon icon={faMobileAlt} className="me-2" style={{color: '#122051'}} />
                                                <h4 className="mt-2" style={{color: '#122051'}}>Mobile Apps</h4>
                                                <div>Our mobile apps provide a user-friendly experience, allowing users to access features and services on the go.</div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} md={6} xs={12}>
                                        <Card style={{ border: '1px solid #122051', padding: '1px', flex: 1 }}>
                                        <Card.Body>
                                                <FontAwesomeIcon icon={faFileAlt} className="me-2" style={{color: '#122051'}} />
                                                <h4 className="mt-2" style={{color: '#122051'}}>CMS</h4>
                                                <div>Our Content Management System simplifies content creation and management, empowering teams to collaborate effectively.</div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row> */}
                                {/* <Row className="p-4">
                                    <Col lg={4} md={6} xs={12}>
                                        <Card style={{ border: '1px solid #122051', padding: '1px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                            <Card.Body className="d-flex flex-column justify-content-between">
                                                <div>
                                                    <FontAwesomeIcon icon={faCode} className="me-2" style={{ color: '#122051' }} />
                                                    <h4 className="mt-2" style={{ color: '#122051' }}>APIs</h4>
                                                </div>
                                                <div>
                                                    Explore our robust APIs designed for seamless integration, enabling developers to create powerful applications efficiently.
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} md={6} xs={12}>
                                        <Card style={{ border: '1px solid #122051', padding: '1px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                            <Card.Body className="d-flex flex-column justify-content-between">
                                                <div>
                                                    <FontAwesomeIcon icon={faMobileAlt} className="me-2" style={{ color: '#122051' }} />
                                                    <h4 className="mt-2" style={{ color: '#122051' }}>Mobile Apps</h4>
                                                </div>
                                                <div>
                                                    Our mobile apps provide a user-friendly experience, allowing users to access features and services on the go.
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4} md={6} xs={12}>
                                        <Card style={{ border: '1px solid #122051', padding: '1px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                            <Card.Body className="d-flex flex-column justify-content-between">
                                                <div>
                                                    <FontAwesomeIcon icon={faFileAlt} className="me-2" style={{ color: '#122051' }} />
                                                    <h4 className="mt-2" style={{ color: '#122051' }}>CMS</h4>
                                                </div>
                                                <div>
                                                    Our Content Management System simplifies content creation and management, empowering teams to collaborate effectively.
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row> */}
                                <Row className="p-4">
                                    <Col lg={4} md={6} xs={12}>
                                        <OverlayTrigger
                                            placement="bottom" // We can change to 'right', 'top', or 'left' as needed
                                            overlay={
                                                <Tooltip id="tooltip-api-description">
                                                    Explore our robust APIs designed for seamless integration, enabling developers to create powerful applications efficiently.
                                                </Tooltip>
                                            }
                                        >
                                            <Card style={{ border: '1px solid #122051', padding: '1px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center">
                                                    <div>
                                                        <FontAwesomeIcon icon={faCode} style={{ color: '#122051', fontSize: '2.5rem' }} />
                                                        <h6 className="mt-2" style={{ color: '#122051' }}>APIs</h6>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </OverlayTrigger>
                                    </Col>
                                    <Col lg={4} md={6} xs={12}>
                                        <OverlayTrigger
                                            placement="bottom" // We can change to 'right', 'top', or 'left' as needed
                                            overlay={
                                                <Tooltip id="tooltip-api-description">
                                                    Our mobile apps provide a user-friendly experience, allowing users to access features and services on the go.
                                                </Tooltip>
                                            }
                                        >
                                            <Card style={{ border: '1px solid #122051', padding: '1px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center">
                                                    <div>
                                                        <FontAwesomeIcon icon={faMobileAlt} style={{ color: '#122051', fontSize: '2.5rem' }} />
                                                        <h6 className="mt-2" style={{ color: '#122051' }}>Mobile Apps</h6>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </OverlayTrigger>
                                    </Col>
                                    <Col lg={4} md={6} xs={12}>
                                        <OverlayTrigger
                                            placement="bottom" // We can change to 'right', 'top', or 'left' as needed
                                            overlay={
                                                <Tooltip id="tooltip-api-description">
                                                    Our Content Management System simplifies content creation and management, empowering teams to collaborate effectively.
                                                </Tooltip>
                                            }
                                        >
                                            <Card style={{ border: '1px solid #122051', padding: '1px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center">
                                                    <div>
                                                        <FontAwesomeIcon icon={faFileAlt} style={{ color: '#122051', fontSize: '2.5rem' }} />
                                                        <h6 className="mt-2" style={{ color: '#122051' }}>CMS</h6>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Second Column with Two Rows */}
                    <Col lg={6} md={6} xs={12}>
                        <Row className="h-80">
                            <Col>
                                <Card style={{ marginBottom: '10px', padding: '5px' }}>
                                    <Card.Body>
                                        <h3 className="mt-2" style={{ color: '#122051' }}>API Documentations</h3>
                                        <div>
                                            <Button
                                                variant="primary"
                                                className="rounded-pill mt-3"
                                                style={{ backgroundColor: '#122051', borderColor: '#122051' }}
                                                onClick={handleClick}
                                            >
                                                <FontAwesomeIcon icon={faExternalLinkAlt} className="me-2" />
                                                Go to Documentation
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="h-80">
                            <Col>
                                <Card style={{ padding: '5px' }}>
                                    <Card.Body>
                                        <h3 className="mt-2" style={{ color: '#122051' }}>Explore More Services</h3>
                                        <div>
                                            <Button
                                                variant="primary"
                                                className="rounded-pill mt-3"
                                                style={{ backgroundColor: '#122051', borderColor: '#122051' }}
                                                onClick={handleClick1}
                                            >
                                                <FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" />
                                                Explore
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SpecaBox;